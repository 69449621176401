.m-button {
  display: inline-flex;
  background: var(--colorMain1);
  color: var(--colorBg1);
  border-radius: 999rem;
  letter-spacing: 0.06em;
  min-width: 225rem;
  min-height: 40rem;
  overflow: hidden;
  text-align: left;
  @include wide {
    transition: .3s;
    min-width: 276rem;
    min-height: 60rem;
    &:hover {
      background: var(--colorText2);
      border-color:(--colorText2);
    }
  }
  &--pc-large {
    @include wide {
      min-width: 450rem;
      min-height: 80rem;
    }
  }
  &--block {
    display: block;
  }
  &--disbiled {
    background: #9fa2a5;
    pointer-events: none;
  }
  &--border {
    border:1px solid var(--colorMain1);
    color:var(--colorMain1);
    background: none;
    &:hover {
      @include wide {
        border-color:var(--colorText2);
        color: var(--colorBg1);
      }
    }
  }
}
.m-button__inner {
  display: flex;
  align-items: center;
  width: 100%;
  padding:6rem 10rem 6rem 0;
  &:after {
    content: '';
    display: block;
    width: 20rem;
    height: 20rem;
    background: url("/assets/img/common/icon_circle_arrow_gray.svg") no-repeat center center / contain;
    margin-right:0;
    margin-left: auto;
    @include wide {
      width: 30rem;
      height: 30rem;
      padding-right: 34rem;
    }
    @at-root .m-button--no-arrow &  {
      content: none;
    }
  }
  .m-button--prev & {
    flex-direction: row-reverse;
    padding-right: 0;
    padding-left: 10rem;
    &:after {
      transform: scale(-1);
    }
  }
  .m-button--pc-large & {
    @include wide {
      padding-right: 20rem;
      &:after {
        width: 38rem;
        height: 38rem;
      }
    }
  }
  .m-button--border:not(:hover) & {
    &:after {
      background-image: url("/assets/img/common/icon_circle_arrow.svg");
    }
  }
}
.m-button__icon {
  display: block;
  width: 15rem;
  font-size: 0;
  margin-right: 6rem;
  @include wide {
    margin-right: 8rem;
  }
  .m-button--pc-large & {
    @include wide {
      width: 30rem;
    }
  }
}
.m-button__text {
  font-weight: 600;
  font-size: 13rem;
  line-height: 1.4;
  letter-spacing: 0.07em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex:1;
  padding:0 16rem 0 40rem;
  @include wide {
    font-size: 18rem;
  }
  .m-button--prev & {
    padding-right: 40rem;
    padding-left: 16rem;
  }
  .m-button--pc-large & {
    padding-left: 16rem;
    @include wide {
      font-size: 23rem;
      letter-spacing: 0.09em;
      justify-content: center;
    }
  }
}


.m-sub-button {
  border:1px solid var(--colorMain1);
  color:var(--colorMain1);
  border-radius: 999rem;
  letter-spacing: 0.06em;
  display: inline-flex;
  min-width: 171rem;
  min-height: 43rem;
  overflow: hidden;
  text-align: left;
  @include wide {
    min-height: 60rem;
    min-width: 200rem;
    transition: .3s;
    &:hover {
      background:var(--colorMain2);
      border-color:var(--colorMain2);
      color: var(--colorBg1);
    } 
  }
}
.m-sub-button__inner {
  display: flex;
  align-items: center;
  padding-right: 12rem;
  padding-bottom: 2rem;
  width: 100%;
  @include wide {
    padding-right: 20rem;
  }
  &:after {
    content: '';
    display: block;
    width: 16rem;
    height: 6rem;
    background: url("/assets/img/common/icon_arrow.svg") no-repeat center center / contain;
    @include wide {
      width: 20rem;
      height: 9rem;
    }
  }
  .m-sub-button:hover & {
    &:after {
      @include wide {
        background-image: url("/assets/img/common/icon_arrow_white.svg");
      }
    }
  }
}
.m-sub-button__text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex:1;
  line-height: 1.4;
  padding:5rem 8rem 5rem 12rem;
  @include wide {
    letter-spacing: 0.02em;
    font-size: 16rem;
  }
}