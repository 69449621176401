.l-header {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--heightHeaderSp);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:0 0 0 var(--sidePaddingSp2);
  background: var(--colorBg1);
  transition: box-shadow .2s;
  @include wide {
    min-width: var(--widthMin);
    height: var(--heightHeaderPc);
    padding:0 0 0 var(--sidePaddingPc1);
  }
  &.is-separate {
    box-shadow: 0px 5rem 15rem -5rem rgba(#596787,0.2);
  }
}
.l-header__logo {
  margin-left: 0;
  margin-right: auto;
}
.l-header__logo__link {
  font-family: var(--fontMincho1);
  font-size: 18rem;
  font-weight: 500;
  color:var(--colorText2);
  letter-spacing: 0;
  display: block;
  @include wide {
    font-size: 28rem;
    padding-bottom: 3rem;
    @media (max-width: 1200px) {
      font-size: 20rem;
      padding-top: 10rem;
      padding-bottom: 0;
    }
  }
}
.l-header__humberger {
  width: var(--heightHeaderSp);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  @include wide {
    display: none;
  }
  body.is-navi-open & {
    @include sp {
      background: var(--colorBg2);
    }
  }
}
.l-header__humberger__line {
  height: 2rem;
  width: 24rem;
  background: var(--colorMain1);
  display: block;
  transition: .2s;
  &:nth-child(2) {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  body.is-navi-open & {
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -2rem;
    }
  }
}
.l-header__contact {
  height: 100%;
  transition:opacity .2s;
  @include wide {
    width: 190rem;
    height: 60rem;
    margin-bottom: auto;
    @media (max-width: 1200px) {
      width: 150rem;
    }
  }
  body.is-navi-open & {
    @include sp {
      opacity: 0;
    }
  }
}
.l-header__contact__link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorMain1);
  color: #fff;
  width: var(--heightHeaderSp);
  height: 100%;
  @include wide {
    width: 100%;
    border-radius: 0 0 0 20rem;
    align-items: flex-end;
    padding-bottom: 6rem;
    transition: .3s;
    &:hover {
      background: var(--colorText2);
    }
  }
}
.l-header__contact__text {
  font-weight: 600;
  letter-spacing: 0;
  font-size: 16rem;
  @media (max-width: 1200px) {
    font-size: 14rem;
  }
}
.l-header__contact__icon {
  display: block;
  width: 25rem;
  font-size: 0;
}
.l-header__navi {
  @include sp {
    position: fixed;
    left: 0;
    top:var(--heightHeaderSp);
    width: 100%;
    height: calc(100% - var(--heightHeaderSp));
    background: var(--colorBg1);
    padding-top: 40rem;
    transition: .5s;
  }
  @include wide {
    margin-right: 5rem;
  }
  body:not(.is-navi-open) & {
    @include sp {
      opacity: 0;
      pointer-events: none;
      transform: translateX(100%);
    }
  }
  body:not(.is-loaded) & {
    display: none;
  }
}
.l-header__menu {
  @include wide {
    display: flex;
  }
  @include sp {
    width: 235rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.l-header__menu__link {
  display: inline-block;
  @include sp {
    font-size: 14rem;
    padding:10rem 0;
    letter-spacing: 0.07em;
  }
  @include wide {
    padding:12rem 15rem 0;
    display: flex;
    align-items: center;
    height: var(--heightHeaderPc);
    @media (max-width: 1200px) {
      padding-right: 7rem;
      padding-left: 7rem;
    }
  }
}
.l-header__menu__link__text {
  position: relative;
  &:after {
    @include wide {
      content: '';
      display: block;
      height: 1rem;
      width: 100%;
      background: var(--colorMain2);
      position: absolute;
      bottom: -3rem;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      transition: .5s;
      transform: translateY(2rem);
    }
  }
  .l-header__menu__item.is-current & ,
  a:hover & {
    &:after {
      @include wide {
        opacity: .6;
        transform: translateY(0);
      }
    }
  }
  .l-header__menu__item.is-current & {
    font-weight: 500;
    color:var(--colorText2);
  }
}
.l-header__form-button {
  text-align: center;
  margin-top: 42rem;
}
.l-header__utility {
  border-top: 1px solid var(--colorMain2);
  margin:35rem var(--sidePaddingSp2) 0;
  padding: 30rem 0;
  display: flex;
}
.l-header__utility__button {
  width:calc(50% - 5rem);
  &:nth-child(odd) {
    margin-right: 10rem;
  }
}