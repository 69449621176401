.m-under-mv {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100rem;
    background: var(--colorBg2);
    z-index: -1;
    @include wide {
      height: 87rem;
    }
  }
  &--no-image {
    &:after {
      content: none;
    }
  }
  &--simple {
    text-align: center;
    height: 90rem;
    padding-top: 18rem;
    @include wide {
      height: 210rem;
      display: flex;
      align-items: center;
      padding-top: 0;
      padding-bottom: 35rem;
    }
  }
}
.m-under-mv__inner {
  @include sp {
    padding-right: 0;
    padding-left: var(--sidePaddingSp2);
  }
  @include wide {
    max-width: 1400rem;
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--sidePaddingPc1);
  }
  .m-under-mv--simple & {
    padding-left: 0;
    @include wide {
      padding-left: 0;
    }
  }
}
.m-under-mv__content {
  @include wide {
    display: flex;
    max-width: 1290rem;
    margin-right: 0;
    margin-left: auto;
  }
}
.m-under-mv__text-area {
  @include sp {
    padding-top: 15rem;
  }
  @include wide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 35rem;
    padding-bottom: 94rem;
    padding-right: var(--sidePaddingPc1);
    width: 398rem;
    flex:none;
    .m-under-mv--no-image & {
      width: 100%;
    }
  }
  .m-under-mv--no-image & {
    padding-top: 18rem;
    padding-bottom: 0;
    min-height: 120rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include wide {
      padding-top: 0;
      min-height: 240rem;
    }
  }
}
.m-under-mv__text-area__head {
  @include wide {
    flex:1;
    display: flex;
    align-items: center;
  }
  .m-under-mv--no-image & {
    flex:1;
    display: flex;
    align-items: center;
  }
}
.m-under-mv__heading {
  font-weight: 600;
  font-size: 21rem;
  letter-spacing: 0.14em;
  line-height: 1.19;
  @include wide {
    font-size: 35rem;
  }
  .m-under-mv--simple & {
    @include wide {
      font-size: 42rem;
    }
  }
}
.m-under-mv__sub {
  font-family: var(--fontEn1);
  color:var(--colorMain2);
  display: block;
  font-weight: 400;
  font-size: 12rem;
  letter-spacing: 0.02em;
  margin-top: 8rem;
  @include wide {
    font-size: 18rem;
    margin-top: 15rem;
  }
}
.m-under-mv__breadcrumb {
  @include sp {
    margin-top: 12rem;
  }
}
.m-under-mv__image {
  position: relative;
  @include sp {
    width: 315rem;
    height: 195rem;
    margin-right: 0;
    margin-left: auto;
  }
  @include wide {
    height: 500rem;
    flex:1;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}