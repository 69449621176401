// メディアクエリ
// -----------------------------------

// メディアクエリ内はカスタムプロパティ使用不可のためscss変数を使用
$breakSpMin: 374px;
$breakSp: 767px;
$breakPc: 768px;

@mixin sp {
	@media (max-width: $breakSp) {
		@content;
	}
}
@mixin spmin {
	@media (max-width: $breakSpMin) {
		@content;
	}
}
@mixin wide {
	@media (min-width: $breakPc) {
		@content;
	}
}