.m-local-menu {
  margin-top: 42rem;
  @include sp {
    margin-right: auto;
    margin-left: auto;
    max-width: 325rem;
  }
  @include wide {
    margin-top: 85rem;
    display: flex;
    flex-wrap: wrap;
  }
}
.m-local-menu__item {
  @include wide {
    width: calc((100% - 34rem) / 3);
    &:not(:nth-child(3n)) {
      margin-right: 17rem;
    }
  }
  &:not(:last-child) {
    @include sp {
      margin-bottom: 15rem;
    }
  }
}
.m-local-menu__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border:1rem solid var(--colorMain1);
  color: var(--colorMain1);
  min-height: 73rem;
  font-size: 16rem;
  letter-spacing: 0.03em;
  position: relative;
  text-align: center;
  line-height: 1.4;
  @include wide {
    font-size: 17rem;
    min-height: 67rem;
    padding:0 45rem;
    line-height: 1.25;
    transition: .3s;
    &:hover {
      background:var(--colorText2);
      border-color:var(--colorText2);
      color: var(--colorBg1);
    }
  }
  &--pc-left {
    @include wide {
      justify-content: center;
      padding-left: 0;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 25rem;
    height: 12rem;
    background: url("/assets/img/common/icon_arrow.svg") no-repeat center center / contain;
    position: absolute;
    right: 16rem;
    top: calc(50% + 1rem);
    transform: translateY(-50%);
  }
  &:hover:after {
    @include wide {
      background-image: url("/assets/img/common/icon_arrow_white.svg");
    }
  }
}