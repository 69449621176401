.l-footer {
  background: var(--colorText1); 
  color: #fff;  
  padding-top: 32rem;
  padding-bottom: 20rem;
  @include wide {
    padding-top: 40rem;
    padding-bottom: 45rem;
  }
  a {
    color:inherit;
  }
}
.l-footer__utility {
  display: flex;
  justify-content: center;
}
.l-footer__utility__item {
  margin:0 10rem;
}
.l-footer__utility__link {
  width: 38rem;
  height: 26rem;
  display: block;
  @include wide {
    transition: .4s;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.l-footer__menu {
  @include wide {
    display: flex;
    justify-content: center;
  }
}
.l-footer__menu__link {
  font-size: 13rem;
  display: inline-block;
  padding:6rem 0;
  @include wide {
    padding:6rem 12rem;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: calc(100% - 24rem);
      height: 1rem;
      background: var(--colorBg1); 
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4rem;
      margin: auto;
      opacity: 0;
      transition: .5s;
      transform: translateY(2rem);
    }
    &:hover:after {
      @include wide {
        opacity: .5;
        transform: translateY(0);
      }
    }
  }
}
.l-footer__copyright {
  text-align: center;
  font-size: 11rem;
  letter-spacing: 0.1em;
  margin-top: 28rem;
  @include wide {
    margin-top: 5rem;
  }
}