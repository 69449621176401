.m-breadcrumb {
  width: 100%;
}
.m-breadcrumb__list {
  display: flex;
  width: 100%;
  overflow: auto;
}
.m-breadcrumb__item {
  font-size: 10rem;
  letter-spacing: 0.06em;
  display: flex;
  align-items: center;
  white-space: nowrap;
  @include wide {
    font-size: 13rem;
    letter-spacing: 0.08em;
  }
  &:not(:first-child) {
    margin-left: 10rem;
    &:before {
      content: '';
      display: block;
      width: 15rem;
      height: 1rem;
      background: var(--colorMain2);
      margin-right: 10rem;
    }
  }
}
.m-breadcrumb__link {
  @include wide {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1rem;
      background: var(--colorText2);
      position: absolute;
      left: 0;
      bottom: 9rem;
      opacity: 0;
      transition: .4s;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
.m-breadcrumb__text {
  display: inline-block;
  padding: 10rem 0;
}