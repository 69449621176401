.m-news-unit__date {
  font-family: var(--fontEn1);
  color: var(--colorMain2);
  letter-spacing: 0;
  display: inline-block;
  font-size: 12rem;
  line-height: 1;
  @include wide {
    font-size: 15rem;
  }
}
.m-news-unit {
  border: solid var(--colorMain2);
  border-width: 1px 0 1px 0;
  padding:5rem 0 10rem;
  @include wide {
    padding:28rem 0 30rem;
  }
  .m-news-unit-list & {
    &:not(:last-child) {
      border-bottom: none;
    }
  }
}
.m-news-unit__title {
  margin-top: -5rem;
  @include wide {
    margin-top: 2rem;
  }
}