.m-body-text {
  font-size: 15rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: .13em;  
}
.m-lead-text {
  text-align: center;
  @include wide {
    font-size: 18rem;
  }
}