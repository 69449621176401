.m-simple-section {
  background: var(--colorBg2);
  padding:40rem 0;
  @include wide {
    padding:90rem 0;
  }
}
.m-simple-section__block {
  &+& {
    margin-top: 30rem;
    @include wide {
      margin-top: 65rem;
    }
  }
}
.m-simple-section__text {
  font-size: 14rem;
  line-height: 1.7;
  @include wide {
    font-size: 15rem;
    line-height: 1.8;
  }
  &+& {
    margin-top: 5rem;
    @include wide {
      margin-top: 20rem;
    }
  }
}
.m-simple-section__list {
  counter-reset: number kana lower-alpha;
  .m-simple-section__list {
    @include wide {
      margin-top: 13rem;
    }
  }
}
.m-simple-section__numbered-text {
  text-indent: -1em;
  padding-left: 1em;
  &+& {
    margin-top: 5rem;
    @include wide {
      margin-top: 12rem;
    }
  }
  &:before {
    font-size: 1.1em;
    position: relative;
    min-width: 1.7em;
    padding-right: .1em;
    content: counter(number) ".";
    counter-increment: number 1;
  }
}