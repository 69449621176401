// 入力パーツ

.m-form-input {
  border:1px solid var(--colorMain2);
  width: 100%;
  height: 37rem;
  padding:0 10rem 2rem;
  font-size: 15rem;
  letter-spacing: 0.05em;
  @include wide {
    height: 55rem;
    padding:0 15rem 0;
  }
  &--textarea {
    height: 175rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    @include wide {
      height: 250rem;
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }
  &--select {
    padding-right: 0;
    padding-left: 0;
    position: relative;
    background: #eff4ff;
    @include wide {
      padding-right: 0;
      padding-left: 0;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% + 1rem);
      right: 10rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9rem 5rem 0 5rem;
      border-color: var(--colorMain2) transparent transparent transparent;
      pointer-events: none;
      @include wide {
        right: 15rem;
        border-width: 10rem 6rem 0 6rem;
      }
    }
  }
}
.m-form-input-text {
  font-size: 14rem;
  font-weight: 600;
}
.m-form-input__select {
  width: 100%;
  height: 100%;
  padding-right: 10rem;
  padding-left: 10rem;
  @include wide {
    padding-right: 15rem;
    padding-left: 15rem;
  }
}
.m-form-input-group {
  display: flex;
  &+.m-form-input {
    margin-top: 10rem;
    @include wide {
      margin-top: 16rem;
    }
  }
}
.m-form-input-group__item {
  width: 100%;
  &:not(:last-child) {
    margin-right: 14rem;
    @include wide {
      margin-right: 22rem;
    }
  }
  &--zipcode {
    display: flex;
    align-items: center;
    width: 238rem;
    &:not(:last-child) {
      margin-right: 10rem;
      @include wide {
        margin-right: 13rem;
      }
    }
    input {
      margin-left: 4rem;
    }
  }
}
.m-form-radio {
  position: relative;
}
.m-form-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.m-form-radio__label {
  display: flex;
  font-size: 14rem;
  line-height: 1.5;
  font-weight: 600;
  position: relative;
  padding:5rem 0 5rem 22rem;
  @include wide {
    font-size: 15rem;
    padding:8rem 0 8rem 28rem;
  }
  &:before {
    content: '';
    display: block;
    width: 15rem;
    height: 15rem;
    border:1px solid var(--colorMain2);
    flex:none;
    position: absolute;
    left: 0;
    top: 8rem;
    @include wide {
      top: 10rem;
      width: 20rem;
      height: 20rem;
    }
  }
  input:checked ~ & {
    &:after {
      content: '';
      display: block;
      background:var(--colorText2);
      width: 7rem;
      height: 7rem;
      position: absolute;
      left: 4rem;
      top: 12rem;
      @include wide {
        width: 10rem;
        height: 10rem;
        top: 15rem;
        left: 5rem;
      }
    }
  }
}


// テーブル

.m-form-table {
  @include wide {
    max-width: 763rem;
    margin-right: auto;
    margin-left: auto;
  }
  &--confirm {
    margin-bottom: 32rem;
    @include wide {
      margin-bottom: 80rem;
    }
  }
}
.m-form-table__row {
  @include wide {
    display: flex;
    align-items: flex-start;
  }
  &:not(:first-child) {
    margin-top: 22rem;
    @include wide {
      margin-top: 40rem;
    }
  }
  &--pc-verical-center {
    @include wide {
      align-items: center;
    }
  }
  .contact-form--confirm & {
    @include wide {
      align-items: center;
    }
    &:not(:first-child) {
      border-top: 1px solid var(--colorMain2);
      padding-top: 15rem;
      margin-top: 15rem;
      @include wide {
        padding-top: 32rem;
        margin-top: 32rem;
      }
    }
  }
}
.m-form-table__row__head {
  display: flex;
  align-items: center;
  @include sp {
    margin-bottom: 4rem;
  }
  @include wide {
    width: 262rem;
    min-height: 55rem;
    flex:none;
    justify-content: space-between;
    margin-right: 22rem;
  }
  .contact-form--confirm & {
    @include wide {
      width: 277rem;
      min-height: auto;
      margin-right: 44rem;
      padding-left: 20rem;
    }
  }
}
.m-form-table__row__body {
  @include wide {
    flex:1;
  }
  &--error {
    .m-form-input {
      border-color: #c6184d;
      background: #fcf3f6;
    }
  }
  .m-form-radio-list {
    @include sp {
      margin-left: 10rem;
      margin-top: 5rem;
    }
  }
}
.m-form-table__title {
  font-weight: 600;
  font-size: 15rem;
  letter-spacing: 0.07em;
  @include wide {
    font-size: 18rem;
    letter-spacing: 0.15em;
  }
}
.m-form-table__required {
  color: var(--colorBg2);
  background: var(--colorText2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  font-weight: 600;
  @include sp {
    margin-left: 8rem;
    margin-top: 2rem;
    width: 34rem;
    height: 15rem;
    font-size: 10rem;
    letter-spacing: 0;
  }
  @include wide {
    font-size: 15rem;
    width: 50rem;
    height: 22rem;
    padding-bottom: 1rem;
    letter-spacing: 0.1em;
  }
}
.m-form-table__error {
  color:#c6184d;
  font-size: 13rem;
  line-height: 1.4;
  letter-spacing: 0.09em;
  margin-top: 7rem;
  @include wide {
    font-size: 14rem;
    margin-top: 9rem;
    &:last-child {
      margin-bottom: -10rem;
    }
  }
}
.m-form-table__confirm-text {
  word-break: break-all;
  @include sp {
    padding-left: 10rem;
    font-size: 14rem;
    line-height: 1.56;
  }
  @include wide {
    font-size: 16rem;
  }
}

// 下部テキスト

.m-form-end-text {
  text-align: center;
  font-size: 14rem;
  margin-top: 26rem;
  letter-spacing: 0.08em;
  @include wide {
    font-size: 16rem;
    margin-top: 38rem;
  }
}

// 同意チェック

.m-form-agree {
  position: relative;
  text-align: center;
  width: fit-content;
  margin-top: 12rem;
  margin-right: auto;
  margin-left: auto;
  @include wide {
    margin-top: 30rem;
  }
}
.m-form-agree__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.m-form-agree__text {
  font-size: 13rem;
  padding:10rem 10rem 10rem 34rem;
  position: relative;
  display: inline-block;
  @include wide {
    font-size: 15rem;
    padding-left: 40rem;
  }
  &:before {
     content: '';
     display: block; 
     width: 23rem;
     height: 23rem;
     border:1rem solid #100b0f;
     position: absolute;
     left: 0;
     top: 50%;
     transform: translateY(-50%);
     @include wide {
      width: 25rem;
      height: 25rem;
     }
  }
  &:after {
     content: '';
     display: block; 
     width: 13rem;
     height: 13rem;
     background:var(--colorMain2);
     position: absolute;
     left: 5rem;
     top: 50%;
     transform: translateY(-50%);
     opacity: 0;
     @include wide {
      width: 15rem;
      height: 15rem;
     }
  }
  .m-form-agree__input:checked ~ & {
    &:after {
      opacity: 1;
    }
  } 
}
.m-form-agree__link {
  position: relative;
  z-index: 2;
  text-decoration: underline;
  display: inline-block;
  padding:5rem 0;
  color:var(--colorMain1);
  font-weight: 500;
  @include wide {
    &:hover {
      text-decoration: none;
    }
  }
}


// 送信ボタンなど

.m-form-button-group {
  margin-top: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  @include sp {
    flex-wrap: wrap;
  }
  @include wide {
    width: 100%;
    max-width: 780rem;
    margin-top: 30rem;
    margin-right: auto;
    margin-left: auto;
  }
  .m-form-button {
    &:not(:last-child) {
      @include sp {
        margin-bottom: 15rem;
      }
      @include wide {
        flex:1;
        .m-button {
          width: 100%;
        }
      }
    }
    &:last-child {
      @include wide {
        margin-right: 22rem;
      }
    }
  }
}

// 完了画面

.m-form-send {
  background: var(--colorBg2);
  padding:45rem 0 50rem 0;
  @include wide {
    padding:65rem 0 85rem 0;
  }
}
.m-form-send__heading {
  text-align: center;
  font-weight: 600;
  line-height: 1.33;
  font-size: 22rem;
  letter-spacing: 0.15em;
  margin-bottom: 22rem;
  @include wide {
    font-size: 25rem;
  }
}
.m-form-send__text {
  @include sp {
    padding: 0 20rem;
  }
  @include wide {
    text-align: center;
    font-size: 15rem;
  }
}
.m-form-send__button {
  margin-top: 28rem;
  text-align: center;
  @include wide {
    margin-top: 48rem;
  }
}