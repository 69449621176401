
// 共通パーツ
//-------------------------------

// 日付
.m-article-date {
  font-family: var(--fontEn1);
  color: var(--colorMain2);
  letter-spacing: 0;
  display: inline-block;
  font-size: 12rem;
  letter-spacing: 0.02em;
  @include wide {
    font-size: 15rem;
  }
}
// カテゴリー
.m-article-category {
  display: flex;
  flex-wrap: wrap;
}
.m-article-category__item {
  background: var(--colorText2);
  color: #fff;
  font-size: 10rem;
  line-height: 1;
  padding:1rem 2rem 2rem;
  letter-spacing: .06em;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @include wide {
    padding:2rem 3rem;
    font-size: 11rem;
  }
  &:not(:last-child) {
    margin-right: 5rem;
  }
}
// 比率固定のサムネイル
.m-article-thumbnail {
  width: 100%;
  padding-top: 67.8%;
  position: relative;
  overflow: hidden;
  border-radius: 10rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    transition: transform .3s;
  }
  a:hover & {
    @include wide {
      img {
        transform: scale(1.03,1.03);
      }
    }
  }
}
// 記事ページでのアイキャッチ
.m-article-eyecatch {
  border-radius: 7.5rem;
  overflow: hidden;
  @include wide {
    border-radius: 15rem;
  }
  img {
    width: 100%;
  }
}

// パネル
//-------------------------------

.m-article-panel {
  .m-article-thumbnail {
    margin-bottom: 5rem;
    @include wide {
      margin-bottom: 10rem;
    }
  }
  .m-article-category {
    margin-right: 10rem;
  }
}
.m-article-panel__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.m-article-panel__title {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;  
  @include sp {
    order:99;
  }
  @include wide {
    margin-top: -2rem;
  }
}

// パネル一覧
//-------------------------------

.m-article-panel-list {
  display: flex;
  flex-wrap: wrap;
  .m-article-panel {
    @include sp {
      width: calc(50% - (15rem / 2));
      &:nth-child(odd) {
        margin-right: 15rem;
      }
      &:nth-child(n+3) {
        margin-top: 20rem;
      }
    }
    @include wide {
      width: calc(50% - (20rem / 2));
      &:nth-child(odd) {
        margin-right: 20rem;
      }
      &:nth-child(n+3) {
        margin-top: 40rem;
      }
    }
  }
  .m-article-panel__title {
    @include sp {
      font-size: 13rem;
      line-height: 1.6;
    }
  }
}

// メニューと横並びのレイアウト
//-------------------------------

.m-article-content {
  background: var(--colorBg2);
  padding-top: 35rem;
  padding-bottom: 50rem;
  @include wide {
    padding-top: 90rem;
    padding-bottom: 86rem;
  }
}
.m-article-content__inner {
  padding-bottom: 10rem;
  display: flex;
  @include sp {
    flex-direction: column;
  }
  @include wide {
    justify-content: space-between;
  }
}
.m-article-content__main {
  @include wide {
    width: calc(100% - 316rem);
    max-width: 780rem;
  }
}
.m-article-content__heading {
  margin-bottom: 8rem;
  @include wide {
    margin-bottom: 20rem;
  }
}
.m-article-content__menu {
  @include sp {
    margin-top: 45rem;
    order:99;
  }
  @include wide {
    width: 280rem;
    flex:none;
    margin-left: 36rem;
  }
}
.m-article-content__menu__block {
  border-top: 1px solid var(--colorText2);
  padding:15rem 0 0 0;
  @include wide {
    padding-top: 20rem;
  }
  &:last-child {
    border-bottom: 1px solid var(--colorText2);
  }
}
.m-article-content__menu__heading {
  margin-bottom: 5rem;
  @include wide {
    margin-bottom: 8rem;
  }
  &.m-category-heading {
    font-size: 15rem;
    padding-left: 20rem;
    @include wide {
      font-size: 20rem;
      padding-left: 26rem;
    }
    &:before {
      width: 13rem;
      height: 13rem;
      top: 8rem;
      border-width: 2.5rem;
      @include wide {
        width: 16rem;
        height: 16rem;
        border-width: 3rem;
        top: 11rem;
      }
    }
  }
}
.m-article-content__menu__list__item {
  &:not(:last-child) {
    border-bottom: 1px solid var(--colorMain2);
  }
}
.m-article-content__menu__link {
  font-weight: 500;
  font-size: 14rem;
  display: block;
  padding:12rem 14rem 12rem 28rem;
  position: relative;
  cursor: pointer;
  @include wide {
    font-size: 18rem;
    padding-top: 14rem;
    padding-bottom: 14rem;
    padding-left: 34rem;
    transition: color .3s;
  }
  &:before {
    content: '';
    display: block;
    width: 7rem;
    height: 7rem;
    border: solid var(--colorMain1);
    border-width: 1rem 1rem 0 0;
    transform: rotate(45deg);
    position: absolute;
    left: 14rem;
    top: calc(50% - 3rem);
    transition: border-color .3s;
    @include wide {
      width: 9rem;
      height: 9rem;
      top: calc(50% - 4rem);
    }
  }
  @include wide {
    &:hover {
      color:var(--colorMain2);
      &:before {
        border-color:var(--colorMain2);
      }
    }
  }
  &.is-current {
    color:var(--colorMain2);
    &:before {
      border-color:var(--colorMain2);
    }
  }
  &--toggle {
    &:before {
      border-width: 0 1rem 1rem 0;
      left: auto;
      right: 15rem;
      @include wide {
        top: calc(50% - 6rem);
      }
    }
    &.is-active {
      &:before {
        border-width: 1rem 0 0 1rem;
        @include wide {
          top: calc(50% - 2rem);
        }
      }
    }
  }
}
.m-article-content__menu__child-list {
  padding-bottom: 16rem;
  transition: .4s;
  @include wide {
    padding-bottom: 22rem;
    padding-top: 3rem;
    padding-left: 20rem;
  }
  &:not(.is-active) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.m-article-content__menu__child-list__item {
  .m-article-content__menu__link {
    padding-top: 0;
    padding-bottom: 0;
    @include wide {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 16rem;
    }
  }
}
.m-article-content__back-button {
  margin-top: 35rem;
  text-align: center;
  @include wide {
    margin-top: 50rem;
  }
}

// 記事ページ
//-------------------------------

.m-article-single {
  background: var(--colorBg1);
  border-radius: 7.5rem;
  padding:20rem 27rem;
  @include wide {
    padding:40rem 50rem 50rem;
    border-radius: 15rem;
  }
}
.m-article-single__head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .m-article-date {
    margin-right: 6rem;
    @include wide {
      font-size: 14rem;
      margin-right: 8rem;
    }
  }
}
.m-article-single__title {
  color:var(--colorText2);
  font-size: 19rem;
  font-weight: 600;
  line-height: 1.36;
  margin-top: 5rem;
  width: 100%;
  @include wide {
    font-size: 27rem;
    margin-top: 10rem;
    letter-spacing: 0.1em;
  }
}
// 投稿エリア
.m-article-single__post-area {
  margin-top: 22rem;
  font-size: 14rem;
  @include wide {
    margin-top: 30rem;
    font-size: 18rem;
  }
  p+p {
    margin-top: 1em;
  }
  h3 {
    font-weight: 600;
    font-size: 17rem;
    line-height: 1.25;
    background: #e3ebfb;
    border-left: 4rem solid var(--colorMain2);
    padding:2rem 5rem 4rem;
    margin-bottom: 12rem;
    @include wide {
      font-size: 24rem;
      margin-bottom: 18rem;
      border-left-width: 5rem;
      padding:6rem 5rem 8rem;
    }
    &:not(:first-child) {
      margin-top: 25rem;
      @include wide {
        margin-top: 40rem;
      }
    }
  }
  h4 {
    font-weight: 600;
    line-height: 1.25;
    font-size: 16rem;
    border-left: 4rem solid var(--colorMain2);
    border-bottom: 1rem solid var(--colorMain2);
    padding:0 0 2rem 5rem;
    margin-bottom: 12rem;
    @include wide {
      font-size: 22rem;
      margin-bottom: 18rem;
      border-left-width: 5rem;
      border-bottom-width: 2rem;
    }
    &:not(:first-child) {
      margin-top: 25rem;
      @include wide {
        margin-top: 40rem;
      }
    }
  }
  h5 {
    font-weight: 600;
    font-size: 15rem;
    line-height: 1.25;
    border-left: 4rem solid #9aa5bf;
    padding-left: 5rem;
    margin-bottom: 12rem;
    @include wide {
      font-size: 20rem;
      margin-bottom: 18rem;
      border-left-width: 5rem;
    }
    &:not(:first-child) {
      margin-top: 25rem;
      @include wide {
        margin-top: 40rem;
      }
    }
  }
  strong {
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    color:var(--colorText2);
    @include wide {
      &:hover {
        text-decoration: none;
      }
    }
    &[target="_blank"] {
			&::after {
        display: inline-block;
				content: '';
				background: url("/assets/img/common/icon_external.svg")no-repeat center center;
				background-size: contain;
        width: 12rem;
        height: 12rem;
        margin-left: 0.2em;
        margin-right: 0.5em;
				@include wide {
					width: 18rem;
					height: 18rem;
				}
			}
    }
  }
  .wp-block-post-excerpt__more-link,
  .wp-block-post-title a {
    display: inline;
  }
  ul,ol {
    list-style-type: none;
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
  ul {
    li {
      padding-left: 0.6em;
      text-indent: -.6em;
      &:before {
        content: '・';
      }
    }
  }
  ol {
    counter-reset: item;
    li {
      padding-left: 1.2em;
      text-indent: -1.2em;
      &:before {
        counter-increment: item;
        content: counter(item)'.';
        margin-right: 0.2em;
      }
    }
  }
  blockquote {
    background: rgba(#e3ebfb,.4);
    padding: 25rem 20rem 30rem;
    border-radius: 8rem;
    position: relative;
    @include wide {
      padding: 28rem 26rem 30rem;
    }
    &:before,&:after {
      font-size: 90rem;
      line-height: 1;
      font-family: var(--fontEn2);
      color:#a7b9d9;
      position: absolute;
      @include wide {
        font-size: 110rem;
      }
    }
    &:before {
      content: '“';
      left: 12rem;
      top: -25rem;
      @include wide {
        top: -30rem;
      }
    }
    &:after {
      content: '”';
      right: 10rem;
      bottom: -60rem;
      @include wide {
        bottom: -75rem;
      }
    }
    &:not(:first-child) {
      margin-top: calc(1.5em + 10rem);
    }
    &:last-child {
      margin-bottom: 10rem;
    }
    cite {
      display: block;
      text-align: right;
      font-style: normal;
      font-size: 13rem;
      line-height: 1.5;
      color: #9195a2;
      margin-top: 10rem;
      @include wide {
        font-size: 16rem;
        margin-top: 15rem;
      }
    }
  }
  figure {
    &:not(:first-child) {
      margin-top: 1.5em;
      @include wide {
        margin-top: 30rem;
      }
    }
  }
  figcaption {
    color: #9195a2;
    line-height: 1.5;
    font-size: 13rem;
    margin-top: 6rem;
    @include wide {
      font-size: 15rem;
    }
  }
}