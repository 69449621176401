.l-contact {
  background: #eee;
  background: var(--colorBg2);
  padding:50rem 0 43rem;
  @include wide {
    padding: 90rem 0;
  }
}


.l-contact__inquiry {
  background: #fff;
  padding:40rem 28rem;
  border-radius: 10rem;
  @include wide {
    display: flex;
    align-items: center;
    border-radius: 20rem;
    padding:40rem 0;
  }
}
.l-contact__inquiry__head-area {
  @include wide {
    width: calc(50% - 50rem);
    flex:none;
  }
}
.l-contact__inquiry__head-area__inner {
  @include wide {
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
  }
}
.l-contact__inquiry__heading {
  text-align: center;
  margin-bottom: 14rem;
  @include wide {
    text-align: left;
    margin-bottom: 30rem;
  }
}
.l-contact__inquiry__text {
  font-size: 13rem;
  @include wide {
    font-size: 15rem;
  }
}
.l-contact__inquiry__body-area {
  @include sp {
    border-top: 1px solid var(--colorMain2);
    margin-top: 28rem;
    padding-top: 28rem;
  }
  @include wide {
    border-left: 1px solid var(--colorMain2);
    flex:1;
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}
.l-contact__inquiry__body-area__inner {
  @include wide {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
  }
}
.l-contact__inquiry__tel {
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}
.l-contact__inquiry__tel__number {
  font-family: var(--fontEn1);
  font-size: 34rem;
  line-height: 1;
  display: inline-flex;
  align-items: flex-start;
  @include wide {
    font-size: 50rem;
  }
}
.l-contact__inquiry__tel__number__sub {
  font-size: 0.5em;
  margin-top: 0.2em;
  width: 33rem;
  display: inline-block;
  @include wide {
    width: 42rem;
  }
}
.l-contact__inquiry__time {
  font-size: 12rem;
  margin-left: 33rem;
  @include wide {
    font-size: 16rem;
    margin-left: 42rem;
    margin-top: 6rem;
  }
}
.l-contact__inquiry__button {
  text-align: center;
  margin-top: 18rem;
  @include wide {
    margin-top: 32rem;
  }
}


.l-contact__access {
  margin-top: 50rem;
  @include wide {
    display: flex;
  }
}
.l-contact__access__map-area {
  @include wide {
    margin-right: 72rem;
    flex:1;
  }
}
.l-contact__access__gmap {
  width: 100%;
  height: 190rem;
  border:none;
  @include wide {
    height: 100%;
  }
}
.l-contact__access__text-area {
  @include sp {
    margin-top: 20rem;
    margin-right: 10rem;
    margin-left: 10rem;
  }
  @include wide {
    width: 100%;
    padding-top: 35rem;
    padding-bottom: 35rem;
    max-width: 423px;
  }
}
.l-contact__access__title {
  font-weight: 600;
  font-size: 17rem;
  @include wide {
    font-size: 28rem;
    margin-top: -8rem;
  }
}
.l-contact__access__adress {
  line-height: 1.5;
  margin-top: 10rem;
  font-weight: 400;
  @include wide {
    font-size: 24rem;
  }
}
.l-contact__access__transport {
  margin-top: 15rem;
  font-size: 13rem;
  line-height: 1.5;
  @include wide {
    font-size: 18rem;
  }
  &+& {
    margin-top: 5rem;
    @include wide {
      margin-top: 7rem;
    }
  }
}