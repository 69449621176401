:root {

  // Design Base Width
  --spDesign: 750;
  --spViewport: 375;

  // break point 
  --breakSpMin: #{$breakSpMin};
  --breakSp: #{$breakSp};
  --breakPc: #{$breakPc};

  // Width & Height  
  --widthDefault: 1180rem;
  --widthMax: 1400rem;
  --widthMin: 1000rem;
  --heightHeaderSp:50rem;
  --heightHeaderPc:80rem;  

  // Base padding  
  --sidePaddingSp1:15rem;
  --sidePaddingSp2:25rem;
  --sidePaddingPc1:36rem;  

  // Font
  --fontBase: YakuHanJP_Noto,'Noto Sans JP','Yu Gothic Medium','游ゴシック Medium', YuGothic, '游ゴシック体', 'メイリオ', sans-serif;
  --fontMincho1:'Noto Serif JP', serif;
  --fontEn1:'Oswald', sans-serif;
  --fontEn2:'EB Garamond', serif;

  // Color
  --colorMain1:#1b2e5a;
  --colorMain2:#7ba3ff;
  --colorBg1: #FFF;
  --colorBg2: #f3f3f3;
  --colorBg3: #e6ebf0;
  --colorText1: #2d2d2d;
  --colorText2: #3154a4;
  //--colorBorder1:#abbcce; 

  // Transition
  --transition1: .35s ease-out;

 }