.m-pagination {
  .wp-pagenavi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--fontEn1);
    font-weight: 500;
    margin-top: 30rem;
    position: relative;
    @include wide {
      max-width: 480rem;
      margin-right: auto;
      margin-left: auto;
      margin-top: 70rem;
    }
  }
  .page ,
  .current {
    width: 27rem;
    height: 27rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 4rem;
    font-size: 15rem;
    @include wide {
      width: 40rem;
      height: 40rem;
      margin:0 6rem;
      transition: .2s;
      &:hover {
        color: var(--colorMain2);
      }
    }
  }
  .current {
    background: var(--colorMain2);
    color: var(--colorBg2);
    @include wide {
      &:hover {
        color: var(--colorBg2);
      }
    }
  }
  .previouspostslink ,
  .nextpostslink {
    font-size: 15rem;
    letter-spacing: 0.08em;
    color:var(--colorText2);
    display: flex;
    align-items: center;
    padding:0 10rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include wide {
      padding:5rem 10rem;
    }
    &:after {
      content: '';
      display: block;
      width: 12rem;
      height: 12rem;
      border:1px solid var(--colorText2);
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 5rem);
      @include wide {
        width: 18rem;
        height: 18rem;
        top: calc(50% - 8rem);
        transition: margin .2s;
      }
    }
    &.disabled {
      color:#bababa;
      pointer-events: none;
      &:after {
        border-color:#bababa;
      }
    }
  }
  .previouspostslink {
    margin-right: auto;
    margin-left: 0;
    padding-left: 20rem;
    left: 0;
    @include wide {
      padding-left: 28rem;
    }
    &:after {
      border-width:  0 0 1px 1px;
      left: 0;
      @include wide {
        border-width:  0 0 2px 2px;
      }
    }
    &:hover {
      &:after {
        @include wide {
          margin-left: -2px;
        }
      }
    }
  }
  .nextpostslink {
    margin-right: 0;
    margin-left: auto;
    padding-right: 20rem;
    right: 0;
    @include wide {
      padding-right: 28rem;
    }
    &:after {
      border-width: 1px 1px 0 0;
      right: 0;
      @include wide {
        border-width: 2px 2px 0 0;
      }
    }
    &:hover {
      &:after {
        @include wide {
          margin-right: -2px;
        }
      }
    }
  }
}