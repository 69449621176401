.l-bottom-banner {
  padding: 50rem 0;
  @include wide {
    padding: 115rem 0;
  }
}
.l-bottom-banner__list {
  @include sp {
    padding:0 10rem;
  }
  @include wide {
    display: flex;
    max-width: 980rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.l-bottom-banner__item {
  width: 100%;
  &:not(:last-child) {
    @include sp {
      margin-bottom: 15rem;
    }
    @include wide {
      margin-right: 20rem;
    }
  }
}
.l-bottom-banner__item__inner {
  @include wide {
    transition: .4s;
    &:hover {
      opacity: 0.7;
    }
  }
}