.m-section-heading__sub {
  font-family: var(--fontEn1);
  color: var(--colorMain2);
  display: block;
  font-size: 12rem;
  line-height: 1.4;
  letter-spacing: 0.06em;
  margin-bottom: 5rem;
  @include wide {
    font-size: 18rem;
    margin-bottom: 5rem;
  }
}
.m-section-heading__main {
  display: block;
  font-size: 21rem;
  line-height: 1.2;
  letter-spacing: 0.12em;
  @include wide {
    font-size: 35rem;
    letter-spacing: 0.12em;
  }
}

.m-extra-heading {
  display: flex;
  align-items: center;
  &--pc-vertical {
    @include wide {
      display: block;
    }
  }
  &--vertical {
    display: block;
  }
}
.m-extra-heading__main {
  font-family: var(--fontEn1);
  font-size: 30rem;
  line-height: 1.4;
  font-weight: 400;
  margin-right: 8rem;
  letter-spacing: 0.06em;
  color:var(--colorText2);
  display: block;
  @include wide {
    font-size: 63rem;
    margin-right: 15rem;
    letter-spacing: 0.04em;
  }
  .m-extra-heading--pc-vertical & {
    @include wide {
      margin-right: 0;
    }
  }
  .m-extra-heading--vertical & {
    margin-right: 0;
  }
}
.m-extra-heading__sub {
  font-size: 13rem;
  letter-spacing: 0;
  padding-top: 4rem;
  display: block;
  letter-spacing: 0.1em;
  @include wide {
    font-size: 28rem;
    letter-spacing: 0.1em;
    padding-top: 6rem;
  }
  .m-extra-heading--pc-vertical & {
    @include wide {
      padding-top: 0;
    }
  }
  .m-extra-heading--vertical & {
    padding-top: 0;
  }
}

.m-category-heading {
  font-size: 18rem;
  font-weight: 600;
  color:var(--colorText2);
  letter-spacing: 0.06em;
  position: relative;
  padding-left: 24rem;
  @include wide {
    font-size: 25rem;
    padding-left: 30rem;
  }
  &:before {
    content: '';
    display: block;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background: #9eb4da;
    border: 3rem solid #cfd9e9;
    position: absolute;
    left: 0;
    top: 10rem;
    @include wide {
      width: 20rem;
      height: 20rem;
      border-width: 4rem;
      top: 13rem;
    }
  }
}

.m-simple-heading {
  font-size: 17rem;
  letter-spacing: 0.12em;
  border-bottom: 1px solid var(--colorMain2);
  padding-bottom: 4rem;
  margin-bottom: 15rem;
  position: relative;
  @include wide {
    font-size: 23rem;
    border-bottom-width:2rem;
    margin-bottom: 32rem;
  }
}